//
// authentication.scss
//

.auth-wrap {
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 34px -15px rgba(0, 0, 0, .24);
    -moz-box-shadow: 0 10px 34px -15px rgba(0, 0, 0, .24);
    box-shadow: 0 10px 34px -15px rgba(0, 0, 0, .24);

    .auth-img {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center
    }

    .auth-img, .login-wrap {
        width: 50%;
    }

    .login-wrap {
        position: relative;
    }
}

@media (max-width: 991.98px) {
    .auth-img, .login-wrap {
        width: 100% !important;
    }
}

@media (max-width: 767.98px) {
    .auth-wrap .auth-img {
        height: 250px !important;
    }
}
