.media {
    display: flex;
    align-items: flex-start;

    .media-body {
        flex: 1
    }

    .media-icon {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: $primary;
        margin-right: 15px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer
    }
}

.required:after {
    content: " *";
    color: #ff0000;
}


.y-scroll {
    position: relative;
    margin-right: 2px;
    overflow-y: scroll;
}

.y-scroll::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.y-scroll::-webkit-scrollbar-track {
    /*  box-shadow: inset 0 0 5px rgba(162, 159, 159, 0.35);
      border-radius: 10px;*/
}

/* Handle */
.y-scroll::-webkit-scrollbar-thumb {
    background: rgb(201, 200, 200);
    border-radius: 10px;
}

/* Handle on hover */
.y-scroll::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}


.wizard-progress {
    display: table;
    width: 100%;
    table-layout: fixed;
    position: relative;

    .step {
        display: table-cell;
        text-align: center;
        vertical-align: top;
        overflow: visible;
        position: relative;
        font-size: 14px;
        color: $primary;
        font-weight: bold;

        &:not(:last-child):before {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            top: 37px;
            background-color: $gray-500;
            height: 6px;
            width: 100%;
        }

        .node {
            display: inline-block;
            border: 6px solid $gray-500;
            background-color: $gray-500;
            border-radius: 18px;
            height: 25px;
            width: 25px;
            position: absolute;
            top: 25px;
            left: 50%;
            margin-left: -18px;
        }

        &.complete {
            &:before {
                background-color: $primary;
            }

            .node {
                border-color: $primary;
                background-color: $primary;

                &:before {
                    font-family: FontAwesome;
                    content: "\f00c";
                }
            }
        }

        &.in-progress {
            &:before {
                background: $primary;
                ackground: -moz-linear-gradient(left, $primary 0%, $gray-500 100%);
                background: -webkit-linear-gradient(left, $primary 0%, $gray-500 100%);
                background: linear-gradient(to right, $primary 0%, $gray-500 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$primary}', endColorstr='#{$gray-500}', GradientType=1);
            }

            .node {
                border-color: $primary;
            }
        }
    }
}


.placeholder-image {
    position: relative;
    width: 100px;
    height: 100px;
    background: $gray-500;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 2px;
    }

    .placeholder-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        background: $gray-700;
        display: none;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
    }

    &:hover .placeholder-overlay {
        display: flex;
    }

}

.icon-wrapper {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($gray-500, 0.3);

    i {
        font-size: 20px;
        color: $gray-500;
    }

    &.icon-sm {
        height: 40px;
        width: 40px;

        i {
            font-size: 22px;
        }
    }

    &.icon-md {
        height: 60px;
        width: 60px;

        i {
            font-size: 28px;
        }
    }

    &.icon-lg {
        height: 80px;
        width: 80px;

        i {
            font-size: 32px;
        }
    }

    &.icon-success {
        background: rgba($success, 0.3) !important;

        i {
            color: $success !important;
        }
    }

    &.icon-danger {
        background: rgba($danger, 0.3) !important;

        i {
            color: $danger !important;
        }
    }

    &.icon-primary {
        background: rgba($primary, 0.3) !important;

        i {
            color: $primary !important;
        }
    }

    &.icon-info {
        background: rgba($info, 0.3) !important;

        i {
            color: $info !important;
        }
    }

    &.icon-warning {
        background: rgba($warning, 0.3) !important;

        i {
            color: $warning !important;
        }
    }
}


.rest-status {
    input[switch] + label {
        width: 75px;
        height: 30px;
    }

    input[switch] + label:before {
        font-size: 15px;
        line-height: 28px;
        right: 3px;
    }

    input[switch]:checked + label:after {
        left: 46px;
        top: 3px;
    }

    input[switch] + label:after {
        height: 24px;
        width: 24px;
        top: 3px;
    }
}

.bg-gray-100 {
    background: $gray-100;
}

.bg-gray-200 {
    background: $gray-200;
}

.bg-gray-300 {
    background: $gray-300;
}

.bg-gray-400 {
    background: $gray-400;
}

.bg-gray-500 {
    background: $gray-500;
}

.bg-gray-600 {
    background: $gray-600;
}

.rounded {
    border-radius: 0.5rem !important;
}

.object-fit-cover {
    object-fit: cover !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.swal2-timer-progress-bar {
    background: red !important;
}
